import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#5a8787',
      light: '#89b7b7',
      dark: '#2d5a5a'
    },
    secondary: {
      main: '#7b4e5a',
      light: '#ab7b87',
      dark: '#4e2531',
    },
    background: {
      default: '#05101c',
      paper: '#02070d',
    },
    custom1: {
      main: '#050d16',
    },
    white1: {
      main: '#FFFFFF',
    },
    black: {
      main: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255,255,255,0.7)',
      disabled: 'rgba(255,255,255,0.5)',
      hint: 'rgba(255,255,255,0.5)',
    },
    divider: 'rgba(255,255,255,0.12)',
  },
  typography: {
    fontFamily: 'Roboto',
  },
});

export default theme;