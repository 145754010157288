/* Talking with a contract often involves transforming data, we recommend you to encapsulate that logic into a class */

import { utils } from 'near-api-js';

import { Buffer } from 'buffer';

window.Buffer = Buffer;

const THIRTY_TGAS = '30000000000000';

export class BattleSite {

  constructor({ contractId, walletToUse }) {
    this.contractId = contractId;
    this.wallet = walletToUse
  }

  async get_all_battles(start) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_all_battles", args: { start: start } })
  }

  async get_active_battles(start) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_active_battles", args: { start: start } })
  }

  async get_complete_battles(start) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_complete_battles", args: { start: start } })
  }

  async get_my_battles(start, walletId) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_my_battles", args: { start: start, walletId: walletId } })
  }

  async get_my_length(walletId) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_my_length", args: { walletId: walletId } })
  }

  async get_active_length() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_active_length", args: {} })
  }

  async get_complete_length() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_complete_length", args: {} })
  }

  async get_battle_by_id(bid) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_battle_by_id", args: { bid : bid } })
  }

  async get_attack_bid(bid) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_attack_bid", args: { bid : bid } })
  }

  async get_msg() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_msg", args: { } })
  }

  async create_battle(deposit) {
    return await this.wallet.callMethod({ contractId: this.contractId, method: "create_battle", args: {}, THIRTY_TGAS, deposit });
  }

  async challenge_battle(bid, deposit) {
    return await this.wallet.callMethod({ contractId: this.contractId, method: "challenge_battle", args: { bid: bid }, THIRTY_TGAS, deposit });
  }

  async attack(bid) {
    const deposit = utils.format.parseNearAmount('0.0002');
    return await this.wallet.callMethod({ contractId: this.contractId, method: "attack", args: { bid: bid }, THIRTY_TGAS, deposit });
  }

  async cancel_battle(bid) {
    const deposit = utils.format.parseNearAmount('0.0002');
    return await this.wallet.callMethod({ contractId: this.contractId, method: "cancel_battle", args: { bid: bid }, THIRTY_TGAS, deposit });
  }

  async claim_prize(bid) {
    const deposit = utils.format.parseNearAmount('0.0002');
    return await this.wallet.callMethod({ contractId: this.contractId, method: "claim_prize", args: { bid: bid }, THIRTY_TGAS, deposit });
  }

}