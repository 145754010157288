import React from "react";
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { utils } from 'near-api-js';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';


export const CreateBattle = ({ battleSite }) => {

  function createBattle(bet){
    searchParams.delete('transactionHashes');
    searchParams.delete('errorMessage');
    searchParams.delete('errorCode');
    searchParams.delete('fx');
    setSearchParams({fx: 'create_battle'});
    battleSite.create_battle(utils.format.parseNearAmount(bet));
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const errCode = searchParams.get('errorCode');
  const txHash = searchParams.get('transactionHashes');
  const fxParam = searchParams.get('fx');

  const [opena, setOpenA] = useState(true);
  const handleClose = () => {
    setOpenA(false);
  };

  function setAlert() {
    if(txHash !== null && fxParam === 'create_battle'){
      return (
        <Alert severity="success" color="success" variant="filled"
          sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 200 }}
        >
          Battle created successfully
        </Alert>
      )
    } else if(errCode !== null && fxParam === 'create_battle') {
      return (
        <Alert severity="error" color="warning" variant="filled"
          sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 200 }}
        >
          Battle was not created
        </Alert>
      )
    }
  }

  return (
    <>
      <Grid container justifyContent={"right"}>
        <Grid
          container
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{maxWidth:'300'}}
          spacing={2}
        >
          <Paper
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              maxWidth: 300,
              m: 1,
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} container justifyContent="center">
                <Typography
                  variant="h6"
                    sx={{
                      fontWeight: 700,
                      letterSpacing: '.2rem',
                      textDecoration: 'none',
                    }}
                  >
                    CREATE BATTLE
                  </Typography>
              </Grid>
              <Grid item xs={4} sm={4} container>
              <Button variant="contained" color="primary" type="submit" onClick={() => createBattle('0.1')}>
                0.1 Ⓝ
              </Button>
              </Grid>
              <Grid item xs={4} sm={4} container>
              <Button variant="contained" color="primary" type="submit" onClick={() => createBattle('0.5')}>
                0.5 Ⓝ
              </Button>
              </Grid>
              <Grid item xs={4} sm={4} container>
              <Button variant="contained" color="primary" type="submit" onClick={() => createBattle('1')}>
                1 Ⓝ
              </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <br/>
      { setAlert() }
    </>
  );
}
