import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { utils } from 'near-api-js';
import InfiniteScroll from 'react-infinite-scroller';
import { CreateBattle } from './CreateBattle'
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function Main ({ battleSite, wallet }) {

  const [myLength, getMyLength] = useState([]);

  useEffect(() => {
    if (wallet.accountId !== undefined) {
      battleSite.get_my_length(wallet.accountId).then(getMyLength);
    }
  }, [battleSite, wallet.accountId]);

  const [myBattle, getMyBattles] = useState([]);

  const getMoreMy = async (page) => {
    let load = myBattle.length;
    await battleSite.get_my_battles(load, wallet.accountId)
    .then((res) => {
      getMyBattles([...myBattle, ...res])
    })
  }

  const [activeLength, getActiveLength] = useState([]);

  useEffect(() => {
    battleSite.get_active_length().then(getActiveLength);
  }, [battleSite]);

  const [activeBattle, getActiveBattles] = useState([]);

  const getMoreActive = async (page) => {
    let load = activeBattle.length;
    await battleSite.get_active_battles(load)
    .then((res) => {
      getActiveBattles([...activeBattle, ...res])
    })
  }

  const [completeLength, getCompleteLength] = useState([]);

  useEffect(() => {
    battleSite.get_complete_length().then(getCompleteLength);
  }, [battleSite]);

  const [completeBattle, getCompleteBattles] = useState([]);

  const getMoreComplete = async (page) => {
    let load = completeBattle.length;
    await battleSite.get_complete_battles(load)
    .then((res) => {
      getCompleteBattles([...completeBattle, ...res])
    })
  };

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  function getDate(date) {
    const unix_date = Math.floor(date/1000000);
    const new_date = new Date(unix_date);
    const final_date = new_date.toLocaleDateString("en-GB");
    return final_date;
  };

  function concatWallet(walletId) {
    if (walletId.length > 15 && walletId === wallet.accountId) {
      return <Typography display="inline" variant="subtitle1" sx={{ color: 'secondary.light', fontSize: 16, fontWeight: 700  }}> {walletId.substring(0,12)}... </Typography>
    } else if (walletId.length > 15) {
      return <Typography display="inline" variant="subtitle1" sx={{ fontSize: 16, fontWeight: 300  }}> {walletId.substring(0,12)}... </Typography>
    } else if (walletId === wallet.accountId) {
      return <Typography display="inline" variant="subtitle1" sx={{ color: 'secondary.light', fontSize: 16, fontWeight:700  }}> {walletId} </Typography>
    } else {
      return <Typography display="inline" variant="subtitle1" sx={{ fontSize: 16, fontWeight:300  }}> {walletId} </Typography>
    }
  };

  return (
    <>
    {wallet.accountId && <CreateBattle battleSite = {battleSite} wallet = {wallet} />}
    <Grid container direction="column" justifyContent={"center"} align="center">
      <Grid item>
      <Typography variant="h4" sx={{ fontWeight: 800, letterSpacing: '.2rem' }} >
        WHAT IS VEX BATTLE?
      </Typography>
      </Grid>
      <Grid item>
      <Typography sx={{ fontSize: 14, fontWeight: 200, letterSpacing: '.1rem' }}>
        Vex Battle is a fun PvP minigame developed by <strong>Vex Labs.</strong> <br/>
        The game is simple, create a 'Battle', with a bet amount (0.1Ⓝ, 0.5Ⓝ or 1Ⓝ).<br/>
        A second player can accept the challenge and bet the same amount.<br/>
        Each player will take turns attacking until one players HP reaches 0.<br/>
        If a player doesn't attack within 2 minutes, the other player can 'automate' his attack.<br/>
        The winner of the battle will WIN all the money (minus a 5% fee).<br/>
        Good luck and happy battling!
      </Typography>
      </Grid>
    </Grid>
    <br/>
    <Box>   
      <Grid container justifyContent={"center"}>
        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Active" sx={{
                        fontWeight: 800,
                        letterSpacing: '.1rem',
                        textDecoration: 'none',
                        fontSize: 16
                      }} />
            <Tab label="Completed" sx={{
                        fontWeight: 800,
                        letterSpacing: '.1rem',
                        textDecoration: 'none',
                        fontSize: 16
                      }} />
            <Tab label="My Battles" sx={{
                        fontWeight: 800,
                        letterSpacing: '.1rem',
                        textDecoration: 'none',
                        fontSize: 16
                      }} />
          </Tabs>
        </Box>
      </Grid>
      <Box sx={{ padding: 3 }}>
        {tabIndex === 0 && (
          <Box>
            <Grid container justifyContent={"center"}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
              <InfiniteScroll
                pageStart={0}
                loadMore={getMoreActive}
                hasMore={activeBattle.length < activeLength}
                loader={<Skeleton variant="rectangular" sx={{ ml: 2 }} width={400} height={20} key={0} />}
              >
                {activeBattle.map((battle, i) =>
                <Paper
                  sx={{
                    p: 2,
                    maxWidth: 800,
                    mb: 2,
                    ml: 2
                  }}
                  key = {i}
                >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={1} >
                    <Typography display="inline" sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}># </Typography>
                    <Typography display="inline" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {battle.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>PLAYER</Typography>
                          {battle.player
                            ? <>{concatWallet(battle.player)}</>
                            : <>&nbsp;</>
                          }
                        <Typography display="inline" variant="subtitle1">
                          {battle.player === battle.winner  && battle.winner
                            ? <EmojiEventsOutlinedIcon color="success" />
                            : <></>
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>CHALLENGER</Typography>
                          {battle.challenger
                            ? <>{concatWallet(battle.challenger)}</>
                            : <>&nbsp;</>
                          }
                        <Typography display="inline" variant="subtitle1">
                          {battle.challenger === battle.winner && battle.winner
                            ? <EmojiEventsOutlinedIcon color="success" />
                            : <></>
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item align="center">
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {utils.format.formatNearAmount(battle.bet)} Ⓝ
                    </Typography>
                  </Grid>
                  <Grid item align="center">
                    <Button variant="contained" color="secondary" component={Link} to={`/${battle.id}`} >View</Button>                  
                  </Grid>
                  <Grid item xs={5} sm={12} align="right">
                    <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                      Created: { getDate(battle.date) }
                    </Typography>
                  </Grid>
                </Grid>
                </Paper>
                )}
              </InfiniteScroll>
              </Grid>
            </Grid>
          </Box>
        )} {tabIndex === 1 && (
          <Box>
            <Grid container justifyContent={"center"}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
              <InfiniteScroll
                pageStart={0}
                loadMore={getMoreComplete}
                hasMore={completeBattle.length < completeLength}
                loader={<Skeleton variant="rectangular" sx={{ ml: 2 }} width={400} height={20} key={0} />}
              >
                {completeBattle.map((battle, i) =>
                <Paper
                  sx={{
                    p: 2,
                    maxWidth: 800,
                    mb: 2,
                    ml: 2
                  }}
                  key = {i}
                >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={1} >
                    <Typography display="inline" sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}># </Typography>
                    <Typography display="inline" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {battle.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>PLAYER</Typography>
                          {battle.player
                            ? <>{concatWallet(battle.player)}</>
                            : <>&nbsp;</>
                          }
                        <Typography display="inline" variant="subtitle1">
                          {battle.player === battle.winner  && battle.winner
                            ? <EmojiEventsOutlinedIcon color="success" />
                            : <></>
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>CHALLENGER</Typography>
                          {battle.challenger
                            ? <>{concatWallet(battle.challenger)}</>
                            : <>&nbsp;</>
                          }
                        <Typography display="inline" variant="subtitle1">
                          {battle.challenger === battle.winner && battle.winner
                            ? <EmojiEventsOutlinedIcon color="success" />
                            : <></>
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item align="center">
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {utils.format.formatNearAmount(battle.bet)} Ⓝ
                    </Typography>
                  </Grid>
                  <Grid item align="center">
                    <Button variant="contained" color="secondary" component={Link} to={`/${battle.id}`} >View</Button>                  
                  </Grid>
                  <Grid item xs={5} sm={12} align="right">
                    <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                      Created: { getDate(battle.date) }
                    </Typography>
                  </Grid>
                </Grid>
                </Paper>
                )}
              </InfiniteScroll>
              </Grid>
            </Grid>
          </Box>
        )} {tabIndex === 2 && (
          <Box>
            <Grid container justifyContent={"center"}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
              <InfiniteScroll
                pageStart={0}
                loadMore={getMoreMy}
                hasMore={myBattle.length < myLength}
                loader={<Skeleton variant="rectangular" sx={{ ml: 2 }} width={400} height={20} key={0} />}
              >
                {myBattle.map((battle, i) =>
                <Paper
                  sx={{
                    p: 2,
                    maxWidth: 800,
                    mb: 2,
                    ml: 2
                  }}
                  key = {i}
                >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={1} >
                    <Typography display="inline" sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}># </Typography>
                    <Typography display="inline" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {battle.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>PLAYER</Typography>
                          {battle.player
                            ? <>{concatWallet(battle.player)}</>
                            : <>&nbsp;</>
                          }
                        <Typography display="inline" variant="subtitle1">
                          {battle.player === battle.winner  && battle.winner
                            ? <EmojiEventsOutlinedIcon color="success" />
                            : <></>
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>CHALLENGER</Typography>
                          {battle.challenger
                            ? <>{concatWallet(battle.challenger)}</>
                            : <>&nbsp;</>
                          }
                        <Typography display="inline" variant="subtitle1">
                          {battle.challenger === battle.winner && battle.winner
                            ? <EmojiEventsOutlinedIcon color="success" />
                            : <></>
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item align="center">
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {utils.format.formatNearAmount(battle.bet)} Ⓝ
                    </Typography>
                  </Grid>
                  <Grid item align="center">
                    <Button variant="contained" color="secondary" component={Link} to={`/${battle.id}`} >View</Button>                  
                  </Grid>
                  <Grid item xs={5} sm={12} align="right">
                    <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                      Created: { getDate(battle.date) }
                    </Typography>
                  </Grid>
                </Grid>
                </Paper>
                )}
              </InfiniteScroll>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
    </>
  );
}
