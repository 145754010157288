import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Footer() {
  return (
    <>
      <Box
        bgcolor="#050d16"
        sx={{ marginTop: "calc(10% + 60px)", bottom: 0, width: "100%" }}
      >
        <Grid
          px={{ xs: 3, sm: 10 }}
          py={{ xs: 3, sm: 10 }}
          container
          justifyContent={"center"}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="top"
            sx={{ width: "90%" }}
            spacing={8}
          >
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    textDecoration: "none",
                  }}
                >
                  VEX LABS
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography
                  sx={{
                    fontWeight: "light",
                    letterSpacing: ".2rem",
                    textDecoration: "none",
                    fontSize: 14,
                  }}
                >
                  This application is still in BETA
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <Box borderBottom={1}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    textDecoration: "none",
                  }}
                >
                  OUR APPS
                </Typography>
              </Box>
              <Box
                mt={1}
                sx={{
                  fontWeight: "light",
                  letterSpacing: ".2rem",
                  textDecoration: "none",
                  fontSize: 14,
                }}
              >
                <Box display="inline" mr={1}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://market.vexedapes.com"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Market
                  </a>
                </Box>
                <Box display="inline" mr={1}>
                  |{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://raffle.vexedapes.com"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Raffle
                  </a>
                </Box>
                <Box display="inline" mr={1}>
                  |{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://battle.vexedapes.com"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Battle
                  </a>
                </Box>
                <Box display="inline">
                  |{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://staking.vexedapes.com"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Staking
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} align="right">
              <Box borderBottom={1}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    textDecoration: "none",
                  }}
                >
                  CONNECT
                </Typography>
              </Box>
              <Box mt={1}>
                <Box display="inline" mr={1}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://paras.id/collection/vexedapesclub.near"
                  >
                    <svg
                      fill="#ffffff"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30px"
                      height="30px"
                    >
                      <path
                        d="M2.063 16 0 0l8.718 1.6c.37.1.714.182 1.03.257 1.188.28 1.992.47 2.427 1.279.55 1.017.825 2.268.825 3.755 0 1.493-.275 2.748-.825 3.765-.55 1.017-1.703 1.525-3.457 1.525H4.942L5.664 16h-3.6zM3.198 3.307l4.706.854c.2.054.385.097.555.137.641.15 1.075.252 1.31.684.297.543.445 1.211.445 2.005 0 .798-.148 1.468-.445 2.012-.297.543-.919.814-1.865.814h-2.15L4.46 9.707l-1.26-6.4z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </Box>
                <Box display="inline" mr={2}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://dsc.gg/vexedapesclub"
                  >
                    <svg
                      fill="#ffffff"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30px"
                      height="30px"
                    >
                      <path d="M17.777 1.326A17.323 17.323 0 0 0 13.503.001a.065.065 0 0 0-.069.033c-.184.328-.389.756-.532 1.092a15.994 15.994 0 0 0-4.801 0 11.057 11.057 0 0 0-.54-1.092A.067.067 0 0 0 7.49 0c-1.5.258-2.934.71-4.274 1.325a.061.061 0 0 0-.028.024C.467 5.416-.279 9.382.087 13.298c.001.02.012.038.027.05a17.417 17.417 0 0 0 5.244 2.65.068.068 0 0 0 .074-.025 12.44 12.44 0 0 0 1.072-1.744.066.066 0 0 0-.036-.093 11.47 11.47 0 0 1-1.638-.78.067.067 0 0 1-.007-.112c.11-.082.22-.168.325-.255a.065.065 0 0 1 .068-.009c3.437 1.569 7.158 1.569 10.554 0a.065.065 0 0 1 .069.008c.105.087.215.174.326.256a.067.067 0 0 1-.006.112c-.523.305-1.067.564-1.639.78a.067.067 0 0 0-.035.093c.315.61.675 1.192 1.072 1.743a.067.067 0 0 0 .073.025 17.36 17.36 0 0 0 5.252-2.65.067.067 0 0 0 .028-.048c.437-4.528-.734-8.461-3.105-11.948a.053.053 0 0 0-.028-.025zm-10.76 9.588c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.888-2.116 1.059 0 1.903.958 1.887 2.116 0 1.166-.836 2.116-1.887 2.116zm6.978 0c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.887-2.116 1.06 0 1.904.958 1.888 2.116 0 1.166-.828 2.116-1.888 2.116z"></path>
                    </svg>
                  </a>
                </Box>
                <Box display="inline">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/vexedapesclub"
                  >
                    <svg
                      fill="#ffffff"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30px"
                      height="30px"
                    >
                      <path d="M17.944 3.987c.013.175.013.35.013.525C17.957 9.85 13.833 16 6.294 16c-2.322 0-4.48-.663-6.294-1.812.33.037.647.05.99.05 1.916 0 3.68-.638 5.089-1.725-1.802-.038-3.313-1.2-3.833-2.8.254.037.508.062.774.062.368 0 .736-.05 1.079-.137-1.878-.376-3.287-2-3.287-3.963v-.05c.546.3 1.18.488 1.853.512A4.02 4.02 0 0 1 .838 2.775c0-.75.203-1.438.558-2.038a11.71 11.71 0 0 0 8.452 4.225 4.492 4.492 0 0 1-.102-.924c0-2.226 1.828-4.038 4.1-4.038 1.18 0 2.245.487 2.994 1.275A8.145 8.145 0 0 0 19.442.3a4.038 4.038 0 0 1-1.802 2.225A8.316 8.316 0 0 0 20 1.9a8.74 8.74 0 0 1-2.056 2.087z"></path>
                    </svg>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
