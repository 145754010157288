import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";

export default function Navigation({ isSignedIn, wallet }) {
  const signIn = () => {
    wallet.signIn();
  };
  const signOut = () => {
    wallet.signOut();
  };

  const displayWallet = () => {
    if (wallet.accountId.length > 13) {
      return `${wallet.accountId.substring(0, 13)}...`;
    } else {
      return `${wallet.accountId}`;
    }
  };

  const [state, setState] = React.useState(false);

  const toggleDrawer = () => {
    setState(true);
  };
  const closeDrawer = () => {
    setState(false);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky" color="custom1" sx={{ zIndex: 1 }}>
          <Container maxWidth={false}>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component={Link}
                to="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                VEX LABS
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton size="large" onClick={toggleDrawer} color="inherit">
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={state}
                  onClick={closeDrawer}
                  PaperProps={{
                    sx: { width: "100%", opacity: "95%" },
                  }}
                >
                  <Box sx={{ p: 5 }}>
                    <Box align="right">
                      <IconButton
                        size="large"
                        onClick={toggleDrawer}
                        color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                    </Box>
                    <Box align="left">
                      <Box
                        color="white"
                        sx={{ textDecoration: "none" }}
                        onClick={closeDrawer}
                        component={Link}
                        to="/app"
                      >
                        <Typography sx={{ p: 2 }} variant="h4">
                          Apps
                        </Typography>
                      </Box>
                      <Box
                        color="white"
                        sx={{ textDecoration: "none" }}
                        onClick={closeDrawer}
                        component="a"
                        href="https://market.vexedapes.com"
                      >
                        <Typography sx={{ p: 2 }} variant="h4">
                          Market
                        </Typography>
                      </Box>
                      <Box
                        color="white"
                        sx={{ textDecoration: "none" }}
                        onClick={closeDrawer}
                        component="a"
                        href="https://raffle.vexedapes.com"
                      >
                        <Typography sx={{ p: 2 }} variant="h4">
                          Raffle
                        </Typography>
                      </Box>
                      <Box
                        color="white"
                        sx={{ textDecoration: "none" }}
                        onClick={closeDrawer}
                        component="a"
                        href="https://battle.vexedapes.com"
                      >
                        <Typography sx={{ p: 2 }} variant="h4">
                          VexBattle
                        </Typography>
                      </Box>
                      <Box
                        color="white"
                        sx={{ textDecoration: "none" }}
                        onClick={closeDrawer}
                        component="a"
                        href="https://staking.vexedapes.com"
                      >
                        <Typography sx={{ p: 2 }} variant="h4">
                          Staking
                        </Typography>
                      </Box>
                      <Box
                        color="white"
                        sx={{ textDecoration: "none" }}
                        onClick={closeDrawer}
                        component="a"
                        target="_blank"
                        href="https://paras.id/collection/vexedapesclub.near"
                      >
                        <Typography sx={{ p: 2 }} variant="h4">
                          Buy NFT
                        </Typography>
                      </Box>
                      <Box sx={{ pt: 2 }}>
                        <Button
                          onClick={closeDrawer}
                          target="_blank"
                          rel="noreferrer"
                          href="https://paras.id/collection/vexedapesclub.near"
                        >
                          <svg
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                          >
                            <path
                              d="M2.063 16 0 0l8.718 1.6c.37.1.714.182 1.03.257 1.188.28 1.992.47 2.427 1.279.55 1.017.825 2.268.825 3.755 0 1.493-.275 2.748-.825 3.765-.55 1.017-1.703 1.525-3.457 1.525H4.942L5.664 16h-3.6zM3.198 3.307l4.706.854c.2.054.385.097.555.137.641.15 1.075.252 1.31.684.297.543.445 1.211.445 2.005 0 .798-.148 1.468-.445 2.012-.297.543-.919.814-1.865.814h-2.15L4.46 9.707l-1.26-6.4z"
                              fillRule="evenodd"
                            ></path>
                          </svg>
                        </Button>
                        <Button
                          onClick={closeDrawer}
                          target="_blank"
                          rel="noreferrer"
                          href="https://dsc.gg/vexedapesclub"
                        >
                          <svg
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                          >
                            <path d="M17.777 1.326A17.323 17.323 0 0 0 13.503.001a.065.065 0 0 0-.069.033c-.184.328-.389.756-.532 1.092a15.994 15.994 0 0 0-4.801 0 11.057 11.057 0 0 0-.54-1.092A.067.067 0 0 0 7.49 0c-1.5.258-2.934.71-4.274 1.325a.061.061 0 0 0-.028.024C.467 5.416-.279 9.382.087 13.298c.001.02.012.038.027.05a17.417 17.417 0 0 0 5.244 2.65.068.068 0 0 0 .074-.025 12.44 12.44 0 0 0 1.072-1.744.066.066 0 0 0-.036-.093 11.47 11.47 0 0 1-1.638-.78.067.067 0 0 1-.007-.112c.11-.082.22-.168.325-.255a.065.065 0 0 1 .068-.009c3.437 1.569 7.158 1.569 10.554 0a.065.065 0 0 1 .069.008c.105.087.215.174.326.256a.067.067 0 0 1-.006.112c-.523.305-1.067.564-1.639.78a.067.067 0 0 0-.035.093c.315.61.675 1.192 1.072 1.743a.067.067 0 0 0 .073.025 17.36 17.36 0 0 0 5.252-2.65.067.067 0 0 0 .028-.048c.437-4.528-.734-8.461-3.105-11.948a.053.053 0 0 0-.028-.025zm-10.76 9.588c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.888-2.116 1.059 0 1.903.958 1.887 2.116 0 1.166-.836 2.116-1.887 2.116zm6.978 0c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.887-2.116 1.06 0 1.904.958 1.888 2.116 0 1.166-.828 2.116-1.888 2.116z"></path>
                          </svg>
                        </Button>
                        <Button
                          onClick={closeDrawer}
                          target="_blank"
                          rel="noreferrer"
                          href="https://twitter.com/vexedapesclub"
                        >
                          <svg
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                          >
                            <path d="M17.944 3.987c.013.175.013.35.013.525C17.957 9.85 13.833 16 6.294 16c-2.322 0-4.48-.663-6.294-1.812.33.037.647.05.99.05 1.916 0 3.68-.638 5.089-1.725-1.802-.038-3.313-1.2-3.833-2.8.254.037.508.062.774.062.368 0 .736-.05 1.079-.137-1.878-.376-3.287-2-3.287-3.963v-.05c.546.3 1.18.488 1.853.512A4.02 4.02 0 0 1 .838 2.775c0-.75.203-1.438.558-2.038a11.71 11.71 0 0 0 8.452 4.225 4.492 4.492 0 0 1-.102-.924c0-2.226 1.828-4.038 4.1-4.038 1.18 0 2.245.487 2.994 1.275A8.145 8.145 0 0 0 19.442.3a4.038 4.038 0 0 1-1.802 2.225A8.316 8.316 0 0 0 20 1.9a8.74 8.74 0 0 1-2.056 2.087z"></path>
                          </svg>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Drawer>
              </Box>
              <Typography
                variant="h5"
                noWrap
                component={Link}
                to="/"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                VEX LABS
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Button
                  onClick={closeDrawer}
                  sx={{ my: 2, color: "white", display: "block", ml: 2 }}
                  component={Link}
                  to="/app"
                >
                  APPS
                </Button>
                <Button
                  onClick={closeDrawer}
                  sx={{ my: 2, color: "white", display: "block", ml: 2 }}
                  href="https://market.vexedapes.com"
                >
                  MARKET
                </Button>
                <Button
                  onClick={closeDrawer}
                  sx={{ my: 2, color: "white", display: "block", ml: 2 }}
                  href="https://raffle.vexedapes.com"
                >
                  RAFFLE
                </Button>
                <Button
                  onClick={closeDrawer}
                  sx={{ my: 2, color: "white", display: "block", ml: 2 }}
                  href="https://battle.vexedapes.com"
                >
                  BATTLE
                </Button>
                <Button
                  onClick={closeDrawer}
                  sx={{ my: 2, color: "white", display: "block", ml: 2 }}
                  href="https://staking.vexedapes.com"
                >
                  STAKE
                </Button>
                <Button
                  onClick={closeDrawer}
                  target="_blank"
                  rel="noreferrer"
                  href="https://paras.id/collection/vexedapesclub.near"
                >
                  <svg
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                  >
                    <path
                      d="M2.063 16 0 0l8.718 1.6c.37.1.714.182 1.03.257 1.188.28 1.992.47 2.427 1.279.55 1.017.825 2.268.825 3.755 0 1.493-.275 2.748-.825 3.765-.55 1.017-1.703 1.525-3.457 1.525H4.942L5.664 16h-3.6zM3.198 3.307l4.706.854c.2.054.385.097.555.137.641.15 1.075.252 1.31.684.297.543.445 1.211.445 2.005 0 .798-.148 1.468-.445 2.012-.297.543-.919.814-1.865.814h-2.15L4.46 9.707l-1.26-6.4z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </Button>
                <Button
                  onClick={closeDrawer}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dsc.gg/vexedapesclub"
                >
                  <svg
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                  >
                    <path d="M17.777 1.326A17.323 17.323 0 0 0 13.503.001a.065.065 0 0 0-.069.033c-.184.328-.389.756-.532 1.092a15.994 15.994 0 0 0-4.801 0 11.057 11.057 0 0 0-.54-1.092A.067.067 0 0 0 7.49 0c-1.5.258-2.934.71-4.274 1.325a.061.061 0 0 0-.028.024C.467 5.416-.279 9.382.087 13.298c.001.02.012.038.027.05a17.417 17.417 0 0 0 5.244 2.65.068.068 0 0 0 .074-.025 12.44 12.44 0 0 0 1.072-1.744.066.066 0 0 0-.036-.093 11.47 11.47 0 0 1-1.638-.78.067.067 0 0 1-.007-.112c.11-.082.22-.168.325-.255a.065.065 0 0 1 .068-.009c3.437 1.569 7.158 1.569 10.554 0a.065.065 0 0 1 .069.008c.105.087.215.174.326.256a.067.067 0 0 1-.006.112c-.523.305-1.067.564-1.639.78a.067.067 0 0 0-.035.093c.315.61.675 1.192 1.072 1.743a.067.067 0 0 0 .073.025 17.36 17.36 0 0 0 5.252-2.65.067.067 0 0 0 .028-.048c.437-4.528-.734-8.461-3.105-11.948a.053.053 0 0 0-.028-.025zm-10.76 9.588c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.888-2.116 1.059 0 1.903.958 1.887 2.116 0 1.166-.836 2.116-1.887 2.116zm6.978 0c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.887-2.116 1.06 0 1.904.958 1.888 2.116 0 1.166-.828 2.116-1.888 2.116z"></path>
                  </svg>
                </Button>
                <Button
                  onClick={closeDrawer}
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/vexedapesclub"
                >
                  <svg
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                  >
                    <path d="M17.944 3.987c.013.175.013.35.013.525C17.957 9.85 13.833 16 6.294 16c-2.322 0-4.48-.663-6.294-1.812.33.037.647.05.99.05 1.916 0 3.68-.638 5.089-1.725-1.802-.038-3.313-1.2-3.833-2.8.254.037.508.062.774.062.368 0 .736-.05 1.079-.137-1.878-.376-3.287-2-3.287-3.963v-.05c.546.3 1.18.488 1.853.512A4.02 4.02 0 0 1 .838 2.775c0-.75.203-1.438.558-2.038a11.71 11.71 0 0 0 8.452 4.225 4.492 4.492 0 0 1-.102-.924c0-2.226 1.828-4.038 4.1-4.038 1.18 0 2.245.487 2.994 1.275A8.145 8.145 0 0 0 19.442.3a4.038 4.038 0 0 1-1.802 2.225A8.316 8.316 0 0 0 20 1.9a8.74 8.74 0 0 1-2.056 2.087z"></path>
                  </svg>
                </Button>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                {isSignedIn ? (
                  <Button variant="contained" onClick={signOut}>
                    {displayWallet()}
                  </Button>
                ) : (
                  <Button variant="contained" onClick={signIn}>
                    Log In
                  </Button>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <br />
    </>
  );
}
