import React from "react";
import { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import ape from './apex.png'
import ape2 from './apex2.png'
import apes from './apexs.png'
import ape2s from './apex2s.png'

/* global BigInt */

export default function Battle ({ battleSite, wallet }) {

  let { battleId } = useParams();

  useEffect(() => {
    // Fetch post using the postSlug
  }, [battleId]);

  let bid = Number(battleId);

  const [battle, getBattle] = useState([]);

  useEffect(() => {
    battleSite.get_battle_by_id(bid).then(getBattle);
  }, [battleSite, bid]);

  const [attack, getAttack] = useState([]);

  useEffect(() => {
      battleSite.get_attack_bid(bid).then(getAttack);
  });

  const pAttack = attack.filter(item => item.player === battle.player).reverse();
  const cAttack = attack.filter(item => item.player === battle.challenger).reverse();



  const [msg, getMsg] = useState([]);

  useEffect(() => {
      battleSite.get_msg().then(getMsg);
  }, [battleSite]);


  function getDate(date) {
    const unix_date = Math.floor(date/1000000);
    const new_date = new Date(unix_date);
    const final_date = new_date.toLocaleString("en-GB");
    return final_date;
  };


  function challengeBattle(bid, bet) {
    battleSite.challenge_battle(bid, bet);
  };

  function attack_fn(bid) {
    battleSite.attack(bid);
  };

  function claim_prize(bid) {
    battleSite.claim_prize(bid);
  };

  function concatWallet(walletId) {
    if (walletId.length > 15) {
      return <> {walletId.substring(0,12)}... </>
    } else {
      return <> {walletId} </>
    }
  };

  function getButtonPlayer(player, att, challenger, winner, update, id, bet){
    const date = Date.now();
    const nndate = date * 1000000;
    const upd = Number(update) + 120000000000;

    if (player === wallet.accountId) {
      if (challenger && !winner) {
        if (att !== player) {
          return <Button variant="contained" color="secondary" onClick={() => attack_fn(id, bet)}>Attack</Button>
        } else if (nndate > upd) {
          return <Button variant="contained" color="secondary" onClick={() => attack_fn(id, bet)}>Automate</Button>
        }
      } else if (winner === player) {
        return <Button variant="contained" color="secondary" onClick={() => claim_prize(id)}>Claim</Button>
      }
    }
  }
  
  return (
    <>
      <Button sx={{ pl: 4, pb:1 }} color="secondary" component={Link} to="/">&lt; Home</Button>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{width:'80%'}}
        >
          <Grid spacing={4} sx={{height:'100%'}} container direction="row">
            <Grid item  xs={12} sm={6} >
              <Grid container justifyContent="space-between">
                <Typography display={"inline"} sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                  {battle.att !== battle.player && <>&#9654; </>}PLAYER
                </Typography>
                <Typography display={"inline"}>{battle.player}</Typography>
                <Typography display="inline" align="right">{battle.hp}</Typography>
              </Grid>
              <Grid><LinearProgress variant="determinate" value={battle.hp} sx={{ height: 20 }} /></Grid>
              <Grid item sm={12} align={"right"}>
                {battle.hp === 0
                 ? <img alt="ape" width="300px" src={apes} />
                 : <img alt="ape" width="300px" src={ape} />
                }
              </Grid>
              <Grid padding={2}>
                {getButtonPlayer(battle.player, battle.att, battle.challenger, battle.winner, battle.update, battle.id, battle.bet)}
              </Grid>
              {pAttack.map((att, i) =>
                <Grid key={i} borderBottom={0.1} borderColor="black.main" padding={1} container direction={"row"}  justifyContent="space-between">
                  <Grid item sm={12} xs={12}>
                    <Typography display={"inline"} sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                      {getDate(att.date)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {att.critical ? <Typography display={"inline"} color="secondary" sx={{ letterSpacing: '.1rem', fontWeight:800, fontSize: 14, textTransform: 'uppercase' }}>Critical Attack </Typography> : <></>}
                    <Typography display={"inline"} sx={{ fontSize: 15,letterSpacing: '.05rem'}}>
                      {concatWallet(att.player)} {msg[att.msg]?.msg} for&nbsp;
                    </Typography>
                    <Typography display={"inline"} color="secondary.light" sx={{ fontSize: 15, fontWeight: 800, letterSpacing: '.1rem'}}>
                      {att.damage}&nbsp;
                    </Typography>
                    <Typography display={"inline"} sx={{ fontSize: 15,letterSpacing: '.05rem'}}>damage.</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item  xs={12} sm={6}>
              <Grid container justifyContent="space-between">
                <Typography display={"inline"} sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                  {battle.att !== battle.challenger && <>&#9654; </>}CHALLENGER
                </Typography>
                <Typography display={"inline"}>{battle.challenger}</Typography>
                <Typography display="inline" align="right">{battle.chp}</Typography>
              </Grid>
              {battle.challenger ? (<>
              <Grid><LinearProgress variant="determinate" color="secondary" value={battle.chp} sx={{ height: 20 }} /></Grid>
              <Grid item sm={12} align={"left"}>
                {battle.chp === 0
                 ? <img alt="ape" width="300px" src={ape2s} />
                 : <img alt="ape" width="300px" src={ape2} />
                }              
              </Grid>
              <Grid padding={2}>
                {getButtonPlayer(battle.challenger, battle.att, battle.player, battle.winner, battle.update, battle.id, battle.bet)}
              </Grid>
              {cAttack.map((att, i) =>
                <Grid key={i} borderBottom={0.1} borderColor="black.main" padding={1} container direction={"row"}  justifyContent="space-between">
                  <Grid item sm={12}  xs={12}>
                    <Typography display={"inline"} sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                    {getDate(att.date)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {att.critical ? <Typography display={"inline"} color="secondary" sx={{ letterSpacing: '.1rem', fontWeight:800, fontSize: 14, textTransform: 'uppercase' }}>Critical Attack </Typography> : <></>}
                    <Typography display={"inline"} sx={{ fontSize: 15,letterSpacing: '.05rem'}}>
                      {concatWallet(att.player)} {msg[att.msg]?.msg} for&nbsp;
                    </Typography>
                    <Typography display={"inline"} color="secondary.light" sx={{ fontSize: 15, fontWeight: 800, letterSpacing: '.1rem'}}>
                      {att.damage}&nbsp;
                    </Typography>
                    <Typography display={"inline"} sx={{ fontSize: 15,letterSpacing: '.05rem'}}>damage.</Typography>
                  </Grid>
                </Grid>
              )}
            </>) : (<>
            {wallet.accountId !== battle.player && wallet.accountId ? <Grid item alignContent="right" display="inline"><Button color="secondary" variant="contained" onClick={() => challengeBattle(battle.id, battle.bet)}>Challenge</Button></Grid> : <Grid>Awaiting Challenger</Grid>}
            </>)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}
